<template>
  <v-app-bar elevation="1">
    <template v-slot:prepend v-if="isMobile">
      <v-app-bar-nav-icon @click="setDrawer" class="primary-color"></v-app-bar-nav-icon>
    </template>
    <!-- <v-btn
			tag="a"
			variant="text"
			:to="{ path: '/login' }"
			class="font-weight-bold primary-color text-h5"
			v-if="rail || isMobile"
		>
			PCN
		</v-btn> -->
    <!-- <div class=""> -->
    <v-app-bar-title v-if="rail || isMobile">
      <div v-if="!drawer || !isMobile">
        <v-img @click="routeHome" class="text-left cursor-pointer" :src="require('@/assets/images/partout-small.png')" :aspect-ratio="1" width="175" height="30"></v-img>
      </div>
    </v-app-bar-title>
    <!-- </div> -->
    <v-spacer></v-spacer>
    <div class="mr-4">
      <Internationalization bg-color="bg-success" variant="outlined" :prepend-inner-icon="'mdi-flag-outline'" :color="'primary-color'" :density="'compact'" />
    </div>
    <!-- Calendar -->
    <v-btn color="blue-grey" icon variant="text" rounded :to="{ name: 'Calendar' }">
      <v-icon color="blue-grey">mdi-calendar-clock-outline</v-icon>
      <v-tooltip class="text-caption" activator="parent" location="bottom">{{ t('calendarTooltip') }}</v-tooltip>
    </v-btn>

    <!-- Profile dropdown -->
    <template v-slot:append>
      <v-menu transition="slide-y-transition" open-delay="250">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar class="bg-primary-color" size="small">
              <v-icon class="text-white" icon="mdi-account-circle"></v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="pa-0">
          <v-card-text class="pa-0">
            <div class="mx-auto text-center pt-5">
              <v-avatar class="bg-primary-color">
                <span class="text-white font-weight-bold">PCN</span>
              </v-avatar>

              <p class="mt-2 pb-4 px-4 text-caption primary-color font-weight-medium">
                {{ email }}
              </p>
              <v-divider></v-divider>
              <div>
                <v-list class="py-0">
                  <v-list-item :to="{ name: 'Profile' }" class="text-left px-6">
                    <template v-slot:prepend>
                      <v-avatar size="x-small" color="indigo-lighten-4">
                        <v-icon size="small"> mdi-account-outline </v-icon>
                      </v-avatar>
                    </template>
                    <span class="text-indigo-lighten-2 font-weight-medium">{{ t('userProfile') }}</span>
                  </v-list-item>
                  <v-divider color="grey-lighten-5"></v-divider>
                  <v-list-item link @click="logOut" class="text-left px-6">
                    <template v-slot:prepend>
                      <v-avatar size="x-small" color="pink-lighten-2">
                        <v-icon size="small"> mdi-logout-variant </v-icon>
                      </v-avatar>
                    </template>
                    <span class="text-red font-weight-medium">{{ t('signOut') }}</span>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-app-bar>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { userStore } from '@/stores/user.js';
import { useAuthStore } from '@/stores/auth.js';
import { useAssetsStore } from '@/stores/assets.js';
import { useBookingsStore } from '@/stores/bookings.js';

import Internationalization from '@/components/pageAsset/Internationalization.vue';

import { useI18n } from 'vue-i18n';

// COMPOSABLES
import SideNavBarComposable from '@/composables/SideNavBarComposable.js';
import snackBarStatus from '@/composables/SnackbarComposable.js';

const { setSnackbarColor } = snackBarStatus();

const authStore = useAuthStore();
const assetStore = useAssetsStore();
const bookingStore = useBookingsStore();
const usersStore = userStore();

const { rail, drawer, setDrawer, isMobile, routeHome } = SideNavBarComposable();
const router = useRouter();

const { t } = useI18n({
  fallbackLocale: 'fr',
  sync: false,
  messages: {
    en: {
      signOut: 'Log Out',
      userProfile: 'User Profile',
      calendarTooltip: 'Calendar Bookings',
    },
    fr: {
      signOut: 'Se déconnecter',
      userProfile: "Profil de l'utilisateur",
      calendarTooltip: 'Calendrier des réservations',
    },
  },
});

const email = computed(() => (authStore.user != null ? authStore.user.email : ''));

const logOut = async () => {
  const logout = await authStore.logout();
  if (logout && logout.succeed) {
    authStore.$reset();
    assetStore.$reset();
    bookingStore.$reset();
    usersStore.$reset();

    await router.push({ name: 'Login' });
  } else {
    setSnackbarColor(true, 'error', logout.message, 5000);
  }
};
</script>
<style lang="scss" scoped>
:deep(.i18n .v-field.v-field--active) {
  padding-left: 8px;
  padding-right: 8px;
}
:deep(.i18n .v-field__prepend-inner),
:deep(.i18n .v-select__selection-text),
:deep(.i18n .v-field__append-inner) {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #669966;
}

:deep(.i18n .v-field__append-inner),
:deep(.i18n .v-field__input),
:deep(.i18n .v-field__prepend-inner) {
  display: flex;
  align-items: center;
  padding-top: unset;
  padding-bottom: unset;
}
:deep(.i18n .v-field--variant-outlined .v-field__outline__start),
:deep(.i18n .v-field--variant-outlined .v-field__outline__end) {
  border-color: #669966;
}
</style>
