<template>
	<v-progress-linear
		color="deep-purple accent-4"
		indeterminate
		height="4"
		v-bind="$attrs"
		bg-color="#65996559"
	></v-progress-linear>
</template>
<script setup></script>
<style lang="scss"></style>
