<template>
  <v-snackbar transition="slide-y-transition" v-model="snackStatus" :timeout="timeOut" :color="snackColor" location="top" absolute>
    <span class="snack-bar font-weight-medium">
      {{ textMessage }}
    </span>
    <template v-slot:actions>
      <v-btn class="font-weight-medium" color="black" variant="tonal" @click="snackStatus = false"> {{ t('various.close') }} </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  color: String,
  status: Boolean,
  text: String,
  timeout: Number,
});

const emits = defineEmits(['updateStatus']);

const { t } = useI18n();

const snackStatus = ref(props.status);
const textMessage = ref(props.text);
const snackColor = ref(props.color);
const timeOut = ref(props.timeout);

watch(
  () => props,
  (value) => {
    snackStatus.value = value.status;
    textMessage.value = value.text;
    snackColor.value = value.color;
    timeOut.value = value.timeout;
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => snackStatus.value,
  (value) => {
    if (!value) {
      emits('updateStatus', false);
    }
  },
  {
    // immediate: true,
    deep: true,
  }
);
</script>

<style scoped>
.snack-bar {
  letter-spacing: 1px;
}
.snack-bar:first-letter {
  text-transform: capitalize;
}
</style>
