import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

let authStore;

// REQUEST
api.interceptors.request.use(
  (config) => {
    if (authStore?.getUser?.accessToken) {
      const { accessToken } = authStore.getUser;
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

function setAuthStore(param) {
  authStore = param;
}

const backend = {
  api,
  setAuthStore,
};

export default backend;
