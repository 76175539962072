<template>
  <v-app-bar class="public-app-bar" flat floating height="74">
    <v-app-bar-title>
      <v-row>
        <v-col cols="8" md="2">
          <v-img @click="home" contain class="text-left cursor-pointer" :src="require('@/assets/images/partout-small.png')" :aspect-ratio="1" width="175" height="30"></v-img>
        </v-col>
      </v-row>
    </v-app-bar-title>

    <template v-slot:append>
      <v-btn :to="{ path: '/' }" class="ma-2 font-weight-bold" size="small" color="blue-grey">
        <v-icon start size="x-large" icon="mdi-account-circle"></v-icon>
        {{ t('auth.login') }}
      </v-btn>
    </template>
  </v-app-bar>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const home = () => {
  router.push({ path: '/' });
};
</script>
<style lang="scss" scoped>
.public-app-bar {
  position: unset !important;

  .login {
    text-transform: capitalize;
    font-weight: bold;
  }
}
</style>
