import { onAuthStateChanged } from 'firebase/auth';
import { useAuthStore } from '@/stores/auth.js';

import { auth } from './firebase.js';

const getCurrentUser = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      auth,
      (user) => {
        removeListener();
        resolve(user);
      },
      // eslint-disable-next-line comma-dangle
      reject
    );
  });

export default async (router) => {
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const hasUser = await getCurrentUser();

    // A special case for pages that is visible either to authenticated or unauthenticated users
    if (to.meta.public) {
      next();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        authStore.setUserAuth({ user: hasUser, loggedIn: true });
        if (hasUser) {
          next();
        } else {
          next({ path: '/login' });
        }
      } else if (hasUser) {
        next({ path: '/' });
      } else {
        next();
      }
    }
  });
};
