import { ref, computed } from 'vue';

const visible = ref(false);
export default () => {
  const progressStatus = computed(() => visible.value);
  const updateStatus = (status) => {
    visible.value = status;
  };
  const setVisible = () => {
    visible.value = !visible.value;
  };
  return { visible, setVisible, progressStatus, updateStatus };
};
