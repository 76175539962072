import { ref, computed } from 'vue';

const snackbar = ref(false);
const snackbarColor = ref('success');
const message = ref(null);
const timeout = ref(2000);
export default () => {
  const getSnackbarColor = computed(() => snackbarColor.value);
  const snackbarStatus = computed(() => snackbar.value);
  const getMessage = computed(() => message.value);
  const getTimeout = computed(() => timeout.value);

  const setSnackbarColor = (status, color, text, timeouts = 2000) => {
    snackbar.value = status;
    snackbarColor.value = color;
    message.value = text;
    timeout.value = timeouts;
  };
  return {
    setSnackbarColor,
    snackbarStatus,
    getSnackbarColor,
    getMessage,
    getTimeout,
  };
};
