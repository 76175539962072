/* eslint-disable import/no-cycle */
import { defineStore } from 'pinia';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateProfile,
  updatePassword,
  signOut,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  sendSignInLinkToEmail,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updatePhoneNumber,
  PhoneAuthProvider,
  deleteUser,
  updateEmail,
  sendEmailVerification,
} from 'firebase/auth';
import { cloneDeep, isEqual, isUndefined } from 'lodash';
import { useAssetsStore } from '@/stores/assets.js';
import { useBookingsStore } from '@/stores/bookings.js';
import { userStore } from '@/stores/user.js';
import { auth } from '@/boots/firebase.js';
import { vueI18n } from '@/i18n/index.js';

const recaptchaVerifier = async () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        // eslint-disable-next-line no-unused-vars
        callback: (response) => {
        },
      },
      // eslint-disable-next-line comma-dangle
      auth
    );
  }
  window.recaptchaVerifier.render();
  //   window.recaptchaWidgetId = await window.recaptchaVerifier.render();
};

const domain = window.location.host;
const redirectToLogin = {
  url: `https://${domain}/login`,
  handleCodeInApp: true,
};

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () => ({
    user: null,
    loggedIn: false,
    loading: false,
  }),
  getters: {
    getUser: (state) => state.user,
    isLoggedIn: (state) => state.loggedIn,
    isLoading: (state) => state.loading,
  },
  actions: {
    async sendSMS(phoneNumber) {
      // set user recaptcha verifier
      const status = { succeed: true, message: null };

      recaptchaVerifier();

      const appVerifier = window.recaptchaVerifier;
      this.loading = true;

      return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          status.message = confirmationResult;
          this.loading = false;
          return status;
        })
        .catch((error) => {
          status.succeed = false;
          status.message = error.message;
          this.loading = false;
          const errorCodes = {
            'auth/captcha-check-failed': vueI18n.t('errorCodes.auth/captcha-check-failed'),
            'auth/invalid-phone-number': vueI18n.t('errorCodes.auth/invalid-phone-number'),
            'auth/missing-phone-number': vueI18n.t('errorCodes.auth/missing-phone-number'),
            'auth/quota-exceeded': vueI18n.t('errorCodes.auth/quota-exceeded'),
            'auth/user-disabled': vueI18n.t('errorCodes.auth/user-disabled'),
            'auth/operation-not-allowed': vueI18n.t('errorCodes.auth/operation-not-allowed'),
            'auth/too-many-requests': vueI18n.t('errorCodes.auth/too-many-requests'),
          };

          if (error.code === undefined) {
            status.message = vueI18n.t('errorCodes.pleaseTryAgain');
          }

          if (error.code !== undefined) {
            status.message = errorCodes[error.code];
          }

          // if (window.recaptchaVerifier) {
          //   window.recaptchaVerifier.recaptcha.reset(window.recaptchaWidgetId);
          //   console.log('reset');
          // }

          return status;
        });
    },

    async verifyOTP(code) {
      const status = { succeed: true, message: null };
      const { confirmationResult } = window;

      if (confirmationResult !== undefined) {
        // const provider = new PhoneAuthProvider(auth);

        try {
          const credential = await PhoneAuthProvider.credential(confirmationResult.verificationId, code);

          status.message = credential;
          return status;
        } catch (error) {
          status.succeed = false;
          status.message = error.message;
          return status;
        }
      }

      status.succeed = false;
      status.message = vueI18n.t('errorCodes.confirmationCodeMissing');
      return status;
    },

    async checkEmail(email) {
      this.loading = true;
      const checkEmail = await fetchSignInMethodsForEmail(auth, email);
      this.loading = false;
      return checkEmail.length > 0;
    },

    // VERIFY EMAIL ADDRESS
    async toVerifyUserEmailLink(email, emailLink) {
      const status = { succeed: true, message: null };

      try {
        const credential = EmailAuthProvider.credentialWithLink(email, emailLink);
        const emailCredential = await reauthenticateWithCredential(auth.currentUser, credential);
        status.message = emailCredential;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        return status;
      }
    },

    async updatePassword(newPassword) {
      const status = { succeed: true, message: null };

      try {
        const pass = await updatePassword(auth.currentUser, newPassword);
        status.message = pass;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/weak-password': vueI18n.t('errorCodes.auth/weak-password'),
          'auth/requires-recent-login': vueI18n.t('errorCodes.auth/requires-recent-login'),
        };

        if (error.code === undefined) {
          status.message = vueI18n.t('errorCodes.somethingWentWrong');
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        return status;
      }
    },

    // UPDATE USER PHONE NUMBER
    async updatePhoneNumber(verificationId, code) {
      const status = { succeed: true, message: null };
      // const provider = new PhoneAuthProvider(auth);

      try {
        const phone = await updatePhoneNumber(auth.currentUser, PhoneAuthProvider.credential(verificationId, code));
        status.message = phone;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/invalid-verification-code': vueI18n.t('errorCodes.auth/invalid-verification-code'),
          'auth/invalid-verification-id': vueI18n.t('errorCodes.auth/invalid-verification-id'),
        };

        if (error.code === undefined) {
          status.message = vueI18n.t('errorCodes.somethingWentWrong');
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        return status;
      }
    },

    async sendEmailLinkVerification(email, actionCodeSettings) {
      const status = { succeed: true, message: null };

      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);

        status.message = vueI18n.t('successMessage.emailVerificationLinkSent');
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
      }

      return status;
    },

    // SIGNIN
    async signin({ email, password }) {
      const status = { succeed: true, message: null };
      this.loading = true;

      try {
        const user = userStore();
        const sign = await signInWithEmailAndPassword(auth, email, password);
        const details = sign.user;

        if (!details.emailVerified) {
          await this.sendVerificationEmail(redirectToLogin);

          this.logout();
          this.loading = false;
          return { status: false, message: 'notVerified' };
        }

        // check if user profile has profile
        const checkUserDetails = await user.findUser(details.uid);
        const profile = checkUserDetails.data;
        this.user = details;
        this.loggedIn = true;

        // if no user profile create one
        if (!checkUserDetails.exists) {
          const payload = {
            id: details.uid,
            data: {
              email: details.email,
              firstName: details.displayName,
              lastName: '',
              phoneNumber: details.phoneNumber,
              photoURL: null,
              // SET USER DEFAULT ARRIVAL/DEPARTURE IN HOURS AND ADD INSTRUCTION VARIABLE
              defaultArrivalHour: '16:00',
              defaultDepartureHour: '12:00',
              commonInstructions: null,
            },
          };

          user.setProfile(payload);
        } else if (!isEqual(this.user.email, profile.email)) {
          const params = cloneDeep(profile);

          params.id = details.uid;
          params.email = this.user.email;
          user.updateProfile(params);
        }

        status.message = vueI18n.t('successMessage.youreLoggedIn');
      } catch (error) {
        let errorMessage = '';

        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = vueI18n.t('errorCodes.auth/invalid-email');
            break;

          case 'auth/user-not-found':
            errorMessage = vueI18n.t('errorCodes.auth/user-not-found');
            break;
          case 'auth/wrong-password':
            errorMessage = vueI18n.t('errorCodes.auth/wrong-password');
            break;
          default:
            errorMessage = vueI18n.t('errorCodes.invalidEmailPassword');
            break;
        }

        status.succeed = false;
        status.message = errorMessage;
      }

      this.loading = false;
      return status;
    },

    // SET AUTH USER
    setUserAuth({ user, loggedIn }) {
      this.user = user;
      this.loggedIn = loggedIn;
    },

    // RESET PASSWORD
    async resetPassword(email, actionCodeSettings) {
      const status = { succeed: true, message: null };
      const settings = isUndefined(actionCodeSettings) ? {} : actionCodeSettings;
      this.loading = true;

      try {
        const sendPassword = await sendPasswordResetEmail(auth, email, settings);
        status.message = sendPassword;
        this.loading = false;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/invalid-email': vueI18n.t('errorCodes.auth/invalid-email'),
          'auth/user-not-found': vueI18n.t('errorCodes.auth/user-not-found'),
        };

        if (error.code === undefined) {
          status.message = vueI18n.t('errorCodes.somethingWentWrong');
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        this.loading = false;
        return status;
      }
    },

    async checkPassword(email, providedPassword) {
      const status = { succeed: true, message: null };
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        providedPassword,
      );

      // Prompt the user to re-provide their sign-in credentials
      // return reauthenticateWithCredential(auth.currentUser, credential).then(() => status).catch((error) => {
      //   status.succeed = false;
      //   status.message = error.message;
      //   return status;
      // });

      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
        status.message = '';
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/wrong-password': 'errorCodes.auth/wrong-password',
          'auth/user-not-found': 'errorCodes.auth/user-not-found',
        };

        if (error.code === undefined) {
          status.message = 'errorCodes.somethingWentWrong';
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        return status;
      }
    },

    // VERIFY PASSWORD RESET
    async verifyPasswordReset(actionCode) {
      const status = { succeed: true, message: null };
      this.loading = true;
      // Verify the password reset code is valid.
      // return verifyPasswordResetCode(auth, actionCode)
      //   .then((email) => ({ email, confirmed: true }))
      //   .catch((error) => error.code);

      try {
        const verify = await verifyPasswordResetCode(auth, actionCode);
        status.message = verify;
        this.loading = false;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/expired-action-code': vueI18n.t('errorCodes.auth/expired-action-code'),
          'auth/invalid-action-code': vueI18n.t('errorCodes.auth/invalid-action-code'),
          'auth/user-disabled': vueI18n.t('errorCodes.auth/user-account-disabled'),
          'auth/user-not-found': vueI18n.t('errorCodes.auth/user-not-found'),
        };

        if (error.code === undefined) {
          status.message = vueI18n.t('errorCodes.somethingWentWrong');
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        this.loading = false;
        return status;
      }
    },

    // CONFIRM PASSWORD RESET
    async confirmPasswordReset(actionCode, newPassword) {
      // return confirmPasswordReset(auth, actionCode, newPassword)
      //   .then((resp) => ({ resp, confirmed: true }))
      //   .catch((error) => error.code);
      const status = { succeed: true, message: null };
      this.loading = true;

      try {
        const confirm = await confirmPasswordReset(auth, actionCode, newPassword);
        this.loading = false;
        status.message = confirm;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        const errorCodes = {
          'auth/expired-action-code': vueI18n.t('errorCodes.auth/expired-action-code'),
          'auth/invalid-action-code': vueI18n.t('errorCodes.auth/invalid-action-code'),
          'auth/user-disabled': vueI18n.t('errorCodes.auth/user-account-disabled'),
          'auth/user-not-found': vueI18n.t('errorCodes.auth/user-not-found'),
          'auth/weak-password': vueI18n.t('errorCodes.auth/weak-password'),
        };

        if (error.code === undefined) {
          status.message = vueI18n.t('errorCodes.somethingWentWrong');
        }

        if (error.code !== undefined) {
          status.message = errorCodes[error.code];
        }

        this.loading = false;
        return status;
      }
    },

    // REGISTER USER
    async registerUser(email, password) {
      const status = { succeed: true, message: null };

      try {
        const register = await createUserWithEmailAndPassword(auth, email, password);
        status.message = register;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        return status;
      }
    },

    async updateUserEmail(email) {
      const status = { succeed: true, message: null };
      const user = auth.currentUser;

      try {
        const result = await updateEmail(user, email);
        status.message = result;
        return status;
      } catch (e) {
        status.succeed = false;
        status.message = e.message;
        return status;
      }
    },

    async sendVerificationEmail(settings) {
      const status = { succeed: true, message: null };

      try {
        // const send = sendEmailVerification(auth.currentUser);
        const send = sendEmailVerification(auth.currentUser, settings);
        status.message = send;
        return status;
      } catch (e) {
        status.succeed = false;
        status.message = e.message;
        return status;
      }
    },

    // UPDATE USER PROFILE
    async updateUserProfile(profileData) {
      const status = { succeed: true, message: null };

      try {
        const profile = await updateProfile(auth.currentUser, profileData);
        status.message = profile;
        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        return status;
      }
    },

    async deleteUser() {
      const status = { succeed: true, message: null };
      const user = auth.currentUser;

      deleteUser(user)
        .then(() => {
          status.message = vueI18n.t('successMessage.userDeletedSuccessful');
          return status;
        })
        .catch((error) => {
          status.succeed = false;
          status.message = error.message;
          return status;
        });
    },

    // LOGOUT
    async logout() {
      this.loading = true;
      const status = { succeed: true, message: null };
      const assetStore = useAssetsStore();
      const bookingStore = useBookingsStore();
      const usersStore = userStore();

      assetStore.$reset();
      bookingStore.$reset();
      usersStore.$reset();

      return signOut(auth)
        .then(() => {
          status.message = vueI18n.t('successMessage.youreLogOut');
          this.loading = false;
          return status;
        })
        .catch((error) => {
          this.loading = false;
          status.succeed = false;
          status.message = error.message;
          return status;
        });
    },
  },
});
