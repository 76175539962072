/* eslint-disable */
import '../node_modules/vue-simple-calendar/dist/style.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createApp } from 'vue';
import LoadScript from 'vue-plugin-load-script';
import { createPinia } from 'pinia';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import boots from './boots/index.js';

import i18n from './i18n/index.js';

import router from './router/index.js';
import DraggerPlugin from 'vue3-images-dragger';
import 'vue3-images-dragger/styles.css';

loadFonts();

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(LoadScript);
app.use(vuetify);
app.use(DraggerPlugin);
app.use(router);
app.use(i18n);

boots(router);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_API_KEY,
  },
});

router.isReady().then(() => app.mount('#app'));
