import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: '',
      component: () => {},
      props: true,
      meta: {
        layout: 'MainLayout',
        requiresAuth: true,
      },
      redirect: () => ({ name: 'Assets' }),
    },
    {
      path: '/booking',
      name: 'Booking',
      component: () => import('@/views/Booking.vue'),
      meta: {
        layout: 'MainLayout',
        requiresAuth: true,
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/views/Profile.vue'),
      props: true,
      meta: {
        // layout: "MainLayout",
        layout: 'MainLayout',
        requiresAuth: true,
      },
    },
    {
      path: '/assets',
      name: 'Assets',
      component: () => import('@/views/Assets.vue'),
      meta: {
        layout: 'MainLayout',
        requiresAuth: true,
      },
    },

    {
      path: '/calendar',
      name: 'Calendar',
      component: () => import('@/views/Feature/Calendar.vue'),
      meta: {
        layout: 'MainLayout',
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Auth/Login.vue'),
      meta: {
        layout: 'BlankLayout',
        requiresAuth: false,
      },
    },
    {
      path: '/asset/:assetId',
      name: 'AssetDetails',
      component: () => import('@/views/AssetsDetails/Details.vue'),
      meta: {
        layout: 'PublicLayout',
        requiresAuth: false,
        public: true,
      },
      props: true,
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@/views/Auth/ResetPassword.vue'),
      meta: {
        layout: 'BlankLayout',
        requiresAuth: false,
      },
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: () => import('@/views/Auth/SignUp.vue'),
      meta: {
        layout: 'BlankLayout',
        requiresAuth: false,
      },
    },
    {
      path: '/update-user-password',
      name: 'UpdateUserPassword',
      component: () => import('@/views/Auth/UpdateUserPassword.vue'),
      meta: {
        layout: 'BlankLayout',
        requiresAuth: false,
      },
    },
    {
      path: '/:catchAll(.*)*',
      name: 'Error404',
      component: () => import('@/views/Error404.vue'),
      meta: {
        layout: 'BlankLayout',
      },
    },
  ],
});

export default router;
