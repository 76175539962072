<template>
  <v-select v-model="locale" item-title="name" item-value="value" class="i18n" :items="items" hide-details v-bind="$attrs"> </v-select>
</template>
<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const items = ref([
  {
    name: 'Français',
    value: 'fr',
  },
  {
    name: 'English',
    value: 'en',
  },
]);
</script>

<style scoped></style>
