<template>
  <v-navigation-drawer v-model="drawer" :rail="isRailMobile" elevation="3" :temporary="isMobile">
    <v-list nav class="overflow-hidden">
      <v-list-item class="primary-color mb-6">
        <div class="d-flex align-center">
          <!-- <v-btn
					tag="a"
					:to="{ path: '/' }"
					variant="text"
					class="font-weight-bold primary-color text-h5 pa-0 ma-0"
				> -->
          <v-btn v-if="!isMobile" @click="setRail" variant="text" icon color="primary-color">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-img
            @click="routeHome"
            v-if="!rail || isMobile"
            class="cursor-pointer"
            :src="require('@/assets/images/partout-small.png')"
            :aspect-ratio="1"
            height="30"
            width="175"
          ></v-img>
          <!-- </v-btn> -->
        </div>
      </v-list-item>
      <template v-for="(navList, navIndex) in sideNavs" :key="navIndex">
        <v-list-item
          :prepend-icon="navList.icon"
          :value="navList.name"
          class="side-nav"
          :class="link === navList.name ? 'very-active' : 'not-active'"
          :active-class="link === navList.name ? 'primary-color' : ''"
          :active="link === navList.name"
          @click="routeTo(navList.name, navList.to)"
        >
          <span v-if="!rail || isMobile" class="font-weight-bold text-uppercase ml-1">
            {{ navList.title }}
          </span>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import SideNavBarComposable from '@/composables/SideNavBarComposable.js';

const {
  rail, drawer, isRailMobile, setRail, isMobile, routeHome,
} = SideNavBarComposable();
const router = useRouter();
const route = useRoute();
const link = ref('dashboard');

const sideNavs = ref([
  {
    title: 'Hébergements',
    name: 'assets',
    to: { path: '/assets' },
    icon: 'mdi-bed-outline',
  },
  {
    title: 'Réservations',
    name: 'booking',
    to: { path: '/booking' },
    icon: 'mdi-calendar-clock',
  },
]);

const routeTo = async (navValue, path) => {
  link.value = navValue;
  await router.push(path);
};

watch(
  () => route.name,
  (name) => {
    link.value = name.toLowerCase();
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
<style lang="scss" scoped>
.side-nav {
  letter-spacing: 0.75px;
  font-size: 13px;
  color: rgb(117, 117, 117);
}
:deep(.v-list-item--variant-text.not-active .v-list-item__overlay) {
  background: #fff !important;
}
</style>
