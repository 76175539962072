import { defineStore } from 'pinia';
import { onSnapshot, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { db, storage } from '@/boots/firebase.js';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export const userStore = defineStore({
  id: 'userStore',
  state: () => ({
    profile: {},
    isInitialized: false,
    isLoading: false,
    error: null,
    isUndefined: false,
    uploadProgress: null,
    user: {},
  }),
  getters: {
    myProfile: (state) => state.profile,
  },
  actions: {
    getUser(id) {
      this.isLoading = true;

      // eslint-disable-next-line no-unused-vars
      const unsubscribe = onSnapshot(
        doc(db, 'users', id),
        (res) => {
          if (res.exists !== undefined) {
            this.profile = res.data();
            this.isLoading = false;
            this.isUndefined = false;

            if (!this.isInitialized) this.isInitialized = true;
          }

          this.isUndefined = true;
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          this.isLoading = false;
          // eslint-disable-next-line no-useless-return
          if (!this.isInitialized) return;
          // eslint-disable-next-line comma-dangle
        }
      );
    },

    async findUser(id) {
      const docSnap = await getDoc(doc(db, 'users', id));

      return { exists: docSnap.exists(), data: docSnap.data() };
    },

    async checkUserExist(id) {
      const docSnap = await getDoc(doc(db, 'users', id));

      return docSnap.exists();
    },

    async updateProfile(payload) {
      const status = { succeed: true, message: null };

      this.isLoading = true;

      try {
        const updateDataRef = doc(db, 'users', payload.id);

        await updateDoc(updateDataRef, {
          // ...payload,
          phoneNumber: payload?.phoneNumber,
          defaultArrivalHour: payload?.defaultArrivalHour,
          defaultDepartureHour: payload?.defaultDepartureHour,
          commonInstructions: payload?.commonInstructions,
          firstName: payload?.firstName,
          lastName: payload?.lastName,
          email: payload?.email,
        });

        status.message = 'Profile updated succesfully.';
        // return status;
      } catch (error) {
        status.message = error.message;
        status.succeed = false;
        // return status;
      }

      this.isLoading = false;
      return status;
    },

    async setProfile(payload) {
      const status = { succeed: true, message: null };

      this.isLoading = true;

      try {
        await setDoc(doc(db, 'users', payload.id.toString()), {
          ...payload.data,
        });

        status.message = 'Profile created succesfully.';
        // return status;
      } catch (error) {
        status.message = error.message;
        status.succeed = false;
        // return status;
      }

      this.isLoading = false;
      return status;
    },

    async uploadImageProfile(imageFile) {
      const status = { succeed: true, message: null };

      return new Promise((resolve) => {
        const fileExtension = imageFile.name.split('.').pop();
        const storageRef = ref(storage, `userProfiles/${uuidv4()}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.isLoading = true;
            this.uploadProgress = progress;
          },
          () => {
            status.succeed = false;
            status.message = 'Unable to upload your profile photo, you can skip these and try again when you login.';
            return resolve(status);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // setImgUrl(downloadURL)
              this.uploadProgress = null;
              this.isLoading = false;
              status.message = downloadURL;
              resolve(status);
            });
            // eslint-disable-next-line comma-dangle
          }
        );
      });
    },
  },
});

// rules_version = '2';
// service firebase.storage {
//   match /b/{bucket}/o {
//     match /{allPaths=**} {
//       allow read, write: if request.auth != null;
//     }
//   }
// }
