import { defineStore } from 'pinia';
import { onSnapshot, collection } from 'firebase/firestore';

import { db, functions, httpsCallable } from '@/boots/firebase.js';
// import backend from '@/api/backend.js';

export const useDevicesStore = defineStore({
  id: 'devicesStore',
  state: () => ({
    devices: [],
    isInitialized: false,
    loading: false,
  }),
  getters: {
    getDevices: (state) => state.devices,
    // eslint-disable-next-line no-return-assign, no-param-reassign
    getDevice: (state) => (id) => state.devices.filter((item) => item.id === id),
    isLoading: (state) => state.loading,
  },
  actions: {
    async getAllDevices() {
      if (!this.isInitialized) {
        this.loading = true;
      }
      // eslint-disable-next-line no-unused-vars
      const unsubscribe = onSnapshot(
        collection(db, 'devices'),
        (snapshot) => {
          const devices = [...this.devices];

          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              devices.push({ ...change.doc.data(), id: change.doc.id });
            } else if (change.type === 'modified') {
              const devicesIndex = devices.findIndex((s) => s.id === change.doc.id);
              if (devicesIndex !== -1) {
                devices[devicesIndex] = {
                  ...change.doc.data(),
                  id: change.doc.id,
                };
              }
            } else if (change.type === 'removed') {
              const devicesIndex = devices.findIndex((s) => s.id === change.doc.id);

              if (devicesIndex !== -1) {
                devices.splice(devicesIndex, 1);
              }
            }
          });

          this.devices = devices;
          if (!this.isInitialized) {
            this.isInitialized = true;
            this.loading = false;
          }
        },
        () => {
          // eslint-disable-next-line no-useless-return
          if (!this.isInitialized) return;
          // eslint-disable-next-line comma-dangle
        }
      );
    },

    async toggleLockState(asset) {
      const status = {
        succeed: true,
        message: null,
      };
      this.loading = true;
      try {
        // @TODO: Use httpsCallable?
        // const { data } = await backend.api.put(`/devices/lockState/${assetId}`);
        // status.message = `${data.message} succesfully.`;

        // send
        const device = this.getDevice(asset.devices.lock);
        const lockState = device.length ? device[0].boltStatus.state : 'unlocked';
        const lockStatus = lockState === 'locked' ? 'unlock' : 'lock';

        const payload = {
          command: lockStatus,
          params: {
            topic: asset.devices.lock.split(':').join('/'),
          },
          assetId: asset.id,
          userId: asset.userId,
        };

        const appCall = httpsCallable(functions, 'updateLockState');

        // console.log(payload, 'payload');
        const updateDeviceLockState = await appCall(payload);

        // console.log(updateDeviceLockState, 'app call result');
        status.succeed = updateDeviceLockState.data.success;
        status.message = updateDeviceLockState.data.message;

        this.loading = false;

        return status;
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        this.loading = false;
        return status;
      }
    },
  },
});
