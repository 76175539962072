<template>
  <v-footer class="elevation-2 py-4" app>
    <v-row justify="center" no-gutters>
      <v-col class="text-right font-weight-medium primary-color text-caption" cols="12">
        &copy;{{ new Date().getFullYear() }} Partout chez nous. {{ t('allRightReserved') }}
      </v-col>
    </v-row>
  </v-footer>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  fallbackLocale: 'fr',
  sync: false,
  messages: {
    en: {
      allRightReserved: 'All Rights Reserved.',
    },
    fr: {
      allRightReserved: 'Tous les droits sont réservés.',
    },
  },
});
</script>
