<template>
  <AppSideBar />
  <AppNavBar />
  <v-main class="bg-gray-light">
    <slot></slot>
  </v-main>
  <AppFooter />
</template>
<script setup>
import { onMounted } from 'vue';
import AppSideBar from '@/components/common/AppSideBar.vue';
import AppNavBar from '@/components/common/AppNavBar.vue';
import AppFooter from '@/components/common/AppFooter.vue';

import { useAuthStore } from '@/stores/auth.js';
import { userStore } from '@/stores/user.js';

const auth = useAuthStore();
const user = userStore();

onMounted(() => {
  // this will only initialised if the page is refresh
  if (!user.isInitialized) {
    user.getUser(auth.user.uid);
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.v-btn--selected {
  background: $color-primary;
  color: #fff;
}
</style>
