import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';

import { useRoute, useRouter } from 'vue-router';

const drawer = ref(null);
const rail = ref(false);

export default () => {
  const route = useRoute();
  const router = useRouter();

  const { mobile } = useDisplay();
  const isMobile = computed(() => mobile.value);
  const isRailMobile = computed(() => !isMobile.value && rail.value);

  const setDrawer = () => {
    drawer.value = !drawer.value;
  };
  const setRail = () => {
    rail.value = !rail.value;
  };

  const routeHome = () => {
    if (route.name !== 'Assets') {
      router.push({ name: 'Assets' });
    }
  };
  const routeProfile = () => {
    if (route.name !== 'profile') {
      router.push({ name: 'profile' });
    }
  };

  return {
    setDrawer,
    setRail,
    isMobile,
    drawer,
    rail,
    isRailMobile,
    routeHome,
    routeProfile,
  };
};
