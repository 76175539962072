/* eslint-disable import/no-cycle */
import { defineStore } from 'pinia';
import { collection, query, where, onSnapshot, doc, getDocs, deleteDoc, orderBy } from 'firebase/firestore';
import { db, functions, httpsCallable } from '@/boots/firebase.js';
import { useAssetsStore } from '@/stores/assets.js';
// import backend from '@/api/backend.js';

export const useBookingsStore = defineStore({
  id: 'bookingsStore',
  state: () => ({
    bookings: [],
    isInitialized: false,
    isLoading: false,
    error: null,
  }),

  getters: {
    getAllBookings: (state) => state.bookings,
    isLoadingStatus: (state) => state.isLoading,
  },

  actions: {
    async getBookingsByAssetId(assetId) {
      const queryBookings = query(collection(db, 'bookings'), where('assetId', '==', assetId));
      const docSnap = await getDocs(queryBookings);

      if (!docSnap.empty) {
        const bookings = [];

        docSnap.forEach((item) => {
          bookings.push({ id: item.id, ...item.data() });
        });

        return { success: true, data: bookings };
      }
      return {
        success: false,
      };
    },

    async getBookings(payload) {
      if (!this.isInitialized) {
        this.isLoading = true;
      }

      const assetStore = useAssetsStore();
      // get all users asset Ids
      const assetIds = await assetStore.getAssetsId();

      // check if user has assets to fetch its bookings
      if (payload.assetId || assetIds.length > 0) {
        const allBookings = payload.assetId
          ? query(collection(db, 'bookings'), where('assetId', '==', payload.assetId), orderBy('arrivalDate', 'asc'))
          : query(collection(db, 'bookings'), where('assetId', 'in', assetIds), orderBy('arrivalDate', 'asc'));

        this.bookings = [];

        // eslint-disable-next-line no-unused-vars
        const unsubscribe = onSnapshot(
          allBookings,
          (snapshot) => {
            const bookings = [...this.bookings];

            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                bookings.push({ ...change.doc.data(), id: change.doc.id });
              } else if (change.type === 'modified') {
                const bookingIndex = bookings.findIndex((s) => s.id === change.doc.id);
                if (bookingIndex !== -1) {
                  bookings[bookingIndex] = {
                    ...change.doc.data(),
                    id: change.doc.id,
                  };
                }
              } else if (change.type === 'removed') {
                const bookingIndex = bookings.findIndex((s) => s.id === change.doc.id);

                if (bookingIndex !== -1) {
                  bookings.splice(bookingIndex, 1);
                }
              }
            });

            this.bookings = bookings;

            if (!this.isInitialized) {
              this.isInitialized = true;
              this.isLoading = false;
            }
          },
          (/* error */) => {
            // this will prevent error form popup when auth is false
            if (!this.isInitialized) {
              this.isLoading = false;
            }
            // eslint-disable-next-line comma-dangle
          }
        );
      }

      this.isLoading = false;
    },

    async saveBooking({ payload }) {
      const status = {
        succeed: true,
        message: null,
      };
      // eslint-disable-next-line no-param-reassign
      delete payload.rangeDate;
      this.isLoading = true;
      try {
        // @TODO: create an httpsCallable from a fake cloudFunction using express ??
        // const createBooking = await httpsCallable(cloudFunctions, 'app');

        // await backend.api.post('/bookings', payload);

        const createBooking = httpsCallable(functions, 'createBooking');
        await createBooking(payload);

        status.message = 'Booking created succesfully.';
      } catch (error) {
        status.succeed = false;
        status.message = error.response?.data?.message;
      }
      this.isLoading = false;
      return status;
    },

    async updateBooking({ payload }) {
      const status = {
        succeed: true,
        message: null,
      };
      // eslint-disable-next-line no-param-reassign
      delete payload.rangeDate;
      this.isLoading = true;
      try {
        // @TODO
        // const updateBooking = await httpsCallable(cloudFunctions, 'app');
        // await backend.api.put('/bookings', payload);
        const updateBooking = httpsCallable(functions, 'updateBooking');
        await updateBooking(payload);

        status.message = 'Booking updated succesfully.';
      } catch (error) {
        status.succeed = false;
        status.message = error.response?.data?.message;
      }
      this.isLoading = false;
      return status;
    },

    async deleteBooking({ payload }) {
      const status = {
        succeed: true,
        message: null,
      };
      this.isLoading = true;
      try {
        await deleteDoc(doc(db, 'bookings', payload.id));
        status.message = 'Deleted succesfully.';
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
      }
      this.isLoading = false;
      return status;
    },
  },
});
