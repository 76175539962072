/* eslint-disable import/no-cycle */
import { defineStore } from 'pinia';
import { collection, query, onSnapshot, doc, where, addDoc, getDoc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '@/boots/firebase.js';
import { useAuthStore } from '@/stores/auth.js';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import axios from 'axios';

export const useAssetsStore = defineStore({
  id: 'assetsStore',
  state: () => ({
    assets: [],
    isInitialized: false,
    isLoading: true,
    error: null,
    uploadProgress: null,
  }),
  getters: {
    getAllAssets: (state) => state.assets,
    getAssetById: (state) => (id) => state.assets.find((asset) => asset.id === id),
    getAllAssetId: (state) => state.assets.map((item) => item.id),
    getIsInitialized: (state) => state.isInitialized,
    getLoadingStatus: (state) => state.isLoading,
  },
  actions: {
    async getAssetRecordById(id) {
      const docSnap = await getDoc(doc(db, 'assets', id));

      if (docSnap.exists()) return { success: true, data: docSnap.data() };

      return { success: false };
    },
    async getAssetsId() {
      const auth = useAuthStore();
      const docSnap = await getDocs(query(collection(db, 'assets'), where('userId', '==', auth.user.uid)));
      const ids = [];

      docSnap.forEach((docs) => { ids.push(docs.id); });

      return ids.length > 0 ? ids : [];
    },
    getAssets() {
      const auth = useAuthStore();

      const search = query(collection(db, 'assets'), where('userId', '==', auth.user.uid));

      if (!this.isInitialized) this.isLoading = true;

      // eslint-disable-next-line no-unused-vars
      const unsubscribe = onSnapshot(
        search,
        (snapshot) => {
          const assets = [...this.assets];

          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              assets.push({ ...change.doc.data(), id: change.doc.id });
            } else if (change.type === 'modified') {
              const assetIndex = assets.findIndex((s) => s.id === change.doc.id);

              if (assetIndex !== -1) {
                assets[assetIndex] = {
                  ...change.doc.data(),
                  id: change.doc.id,
                };
              }
            } else if (change.type === 'removed') {
              const assetIndex = assets.findIndex((s) => s.id === change.doc.id);

              if (assetIndex !== -1) assets.splice(assetIndex, 1);
            }
          });

          this.assets = assets;

          if (!this.isInitialized) {
            this.isInitialized = true;
            this.isLoading = false;
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          // this will prevent error form popup when auth is false
          if (!this.isInitialized) this.isLoading = false;
        },
      );
    },
    async submitAsset({ payload }) {
      const status = {
        succeed: true,
        message: null,
      };

      this.isLoading = true;

      try {
        if (payload.id) {
          const updateDataRef = doc(db, 'assets', payload.id);

          await updateDoc(updateDataRef, { ...payload });

          this.isLoading = false;
          status.message = 'Assset updated succesfully.';

          return status;
        }

        await addDoc(collection(db, 'assets'), { ...payload });

        status.message = 'Assset created succesfully.';
      } catch (error) {
        status.succeed = false;
        status.message = error.message;
        console.log(error.message, 'message');
      }

      this.isLoading = false;
      return status;
    },
    async uploadImageAsPromise(imageFile) {
      return new Promise((resolve) => {
        const fileExtension = imageFile.name.split('.').pop();
        const storageRef = ref(storage, `files/${uuidv4()}.${fileExtension}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.isLoading = true;
            this.uploadProgress = progress;
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              this.uploadProgress = null;
              this.isLoading = false;

              resolve(downloadURL);
            });
          },
        );
      });
    },
    async deleteAsset({ payload }) {
      const status = { succeed: true, message: null };

      this.isLoading = true;

      try {
        deleteDoc(doc(db, 'assets', payload.id));
        status.message = 'Deleted succesfully.';
      } catch (error) {
        status.message = error.message;
        status.succeed = false;
        console.log('error deleteAsset 2', error);
      }

      this.isLoading = false;
      return status;
    },
    async fetchAssetTimezone({ latitude, longitude }) {
      const timeStamp = moment(new Date()).unix();
      // %2C is for comma character in URL;
      // See DOCUMENTATION HERE: https://developers.google.com/maps/documentation/timezone/get-started#sample-reques
      // by ivy quito ~
      const url = 'https://maps.googleapis.com/maps/api/timezone/json'
      + `?location=${latitude}%2C${longitude}`
      + `&timestamp=${timeStamp}&key=${process.env.VUE_APP_FIREBASE_API_KEY}`;
      let timezone = '';

      await axios.get(url)
        .then((response) => { timezone = response.data.timeZoneId; })
        .catch((error) => console.log(error));

      return timezone;
    },
  },
});
