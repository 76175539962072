<template>
  <v-app>
    <ProgressBar v-if="progressStatus" class="progress" color="#669966" />

    <component :is="layout" :key="$route.path">
      <router-view :key="$route.path"></router-view>
    </component>

    <SnackBar :color="getSnackbarColor" :status="snackbarStatus" :text="getMessage" :timeout="getTimeout" @updateStatus="setSnackBarDefault" />
  </v-app>
</template>

<script>
import { computed, defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import backend from '@/api/backend.js';

// STORES
import { useAuthStore } from '@/stores/auth.js';
import { useAssetsStore } from '@/stores/assets.js';
import { useDevicesStore } from '@/stores/devices.js';
import { useBookingsStore } from '@/stores/bookings.js';

import MainLayout from '@/layouts/MainLayout.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';

import ProgressBar from '@/components/common/widgets/progressBar.vue';
import SnackBar from '@/components/common/widgets/snackBar.vue';
import progressBarStatus from '@/composables/ProgressBarComposables.js';
import snackBarInit from '@/composables/SnackbarComposable.js';
import PublicLayout from '@/layouts/PublicLayout.vue';

export default defineComponent({
  components: {
    BlankLayout,
    PublicLayout,
    MainLayout,
    ProgressBar,
    SnackBar,
  },
  setup() {
    const authStore = useAuthStore();
    const assetStore = useAssetsStore();
    const deviceStore = useDevicesStore();
    const bookingStore = useBookingsStore();

    // Backend initialisation
    backend.setAuthStore(useAuthStore());

    /* eslint-disable */
    const route = useRoute();
    const { progressStatus, updateStatus } = progressBarStatus();

    const { getSnackbarColor, setSnackbarColor, snackbarStatus, getMessage, getTimeout } = snackBarInit();
    const layout = computed(() => (route.meta.hasOwnProperty('layout') ? route.meta.layout : 'MainLayout'));

    const setSnackBarDefault = (val) => {
      setSnackbarColor(val, 'success', null);
    };

    // WATCHER
    // AUTHSTORE
    watch(
      () => authStore.loading,
      (val) => updateStatus(val)
    );
    // ASSETSTORE
    watch(
      () => assetStore.isLoading,
      (val) => updateStatus(val)
    );
    // DEVICESTORE
    watch(
      () => deviceStore.loading,
      (val) => updateStatus(val)
    );
    // BOOKINGSTORE
    watch(
      () => bookingStore.loading,
      (val) => updateStatus(val)
    );

    return {
      layout,
      progressStatus,
      getSnackbarColor,
      snackbarStatus,
      getTimeout,
      getMessage,
      setSnackBarDefault,
    };
  },
});
</script>

<style lang="scss">
.progress {
  z-index: 9999;
  color: red;
  position: absolute;
}
</style>
